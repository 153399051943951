const state = {
    breakpoints : [
        'xxxxs', 'xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'
    ],
    viewport : 'unrecognized'
}

const getters = {
    breakpoints : state => state.breakpoints,
    viewport : state => state.viewport
}

const mutations = {
    setBreakpoints(state, data){
        state.breakpoints = data;
    },
    setViewport(state, data){
        state.viewport = data;
    }
}

const actions = {

}

export default {
    state,
    mutations,
    getters,
    actions
};