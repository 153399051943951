const state = {
    internalView : 0
}

const getters = {
    internalView : state => state.internalView,
}

const mutations = {
    setInternalView(state, param){
        state.internalView = param;
    }
}

export default {
    state,
    mutations,
    getters
};