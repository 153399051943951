<template>
	<div v-if="overlayActive" role="dialog" aria-modal="true" :aria-label="overlayContent" class="overlay_container" v-bind:class="{ active: overlayActive }">
		<div class="page_cover"></div>
		<section class="overlay container-fluid">
			<div class="close_button" aria-label="Close" role="button" tabindex="0" @click="hideOverlay"></div>
			<div class="row align-items-center justify-content-center h-100">
				<div class="col col-sm-10 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
					<div class="overlay_content">
						<div v-if="overlayContent === 'What Is Eroi'">
							<h2>The eROI counter tracks water, energy, waste and greenhouse gas benefits delivered to customers through key Ecolab{{ '\u00a0' }}technologies.</h2>
							<p>Our eROI impact delivered by each technology is a calculation based on historical and forecasted marketing and sales data including the quantity of units sold or number of sold sites. The estimation is updated annually to account for changes in business opportunities. The savings delivered for a single year assumes all business opportunities started with the same baseline at the beginning of the year. Net accounts started with the same relative baseline, typically a traditional program, at the beginning of the year.</p>
							<button @click="whatIsEroiClick" class="button learn_more">Learn More</button>
						</div>
						<div v-else-if="overlayContent === 'About This Tool'">
							<h2>The eROI counter tracks estimated water, energy, waste and greenhouse gas benefits delivered to global customers through key Ecolab solutions and{{ '\u00a0' }}expertise.</h2>
							<p>Our eROI impact is based on historical and forecasted marketing and sales data. The estimation is updated annually to account for changes in market growth and new technologies. Ecolab’s eROI methodology has been independently reviewed by third-party consultant group Anthesis LLC and verified appropriate systems for collection, aggregation and analysis of quantitative data for determination of the potential savings and benefits of its products and services for the stated period and boundaries.</p>
							<button @click="aboutThisToolClick" class="button learn_more">Learn More</button>
						</div>
						<div v-else-if="overlayContent === 'applicationSelector'">
							<application-selector></application-selector>
							<button class="apply" @click="hideOverlay">Apply</button>
						</div>
						<div v-else-if="overlayContent === 'liveCounter'">
							<h2>Tracks water, energy, waste and greenhouse gas benefits delivered to customers through key Ecolab technologies.</h2>
						</div>
						<div v-else-if="overlayContent === 'tvd'">
							<h2>Total Value Delivered is the monetization of the combined benefits of our solutions and services to help customers quantify their return on investment.</h2>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import applicationSelector from "@/views/partials/customer-impact/applicationSelector.vue";
import {mapGetters} from 'vuex';
export default {
	data : function () {
		return {
			showOverflow : true,
			currentPath : window.location.pathname,
			overlayActive : false,
			overlayContent : ""
		}
	},
	mounted(){
		 // Close modal with 'esc' key


	},
	watch : {
		'$route' :function(to, from) {
			if(to.path !== from.path){
				this.currentPath = to.path
			}
		},
		overlayKey : function(key){
			if(!key){
				return;
			}

			this.showOverlay(key);
			this.$store.commit('setOverlayKey', false)
		}
	},
	methods: {
		addKeyDown : function(self, e){
			if(this.overlayActive){

				//ADA
				var dialog = document.querySelector('.overlay_container');
				var firstFocusableElement = dialog.querySelector('.close_button');
				var lastFocusableElement = dialog.querySelector('.learn_more');

				if (e.keyCode == 27 /*esc*/) {
				    if(self.overlayActive){
				        self.hideOverlay();
				    }
				}

				if(e.target == firstFocusableElement && e.key == 'Enter') {
					if(self.overlayActive){
				        self.hideOverlay();
				    }
				}

				if(e.target == firstFocusableElement && e.keyCode == 32) {
					if(self.overlayActive){
				        self.hideOverlay();
				    }
				}

			  if(e.target == firstFocusableElement && e.key == 'Tab' && e.shiftKey) {
			    e.preventDefault();
			    lastFocusableElement.focus();
			  } else if(e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
			    e.preventDefault();
			    firstFocusableElement.focus();
			  }


			}

		},
		aboutThisToolClick : function(){
			// this is so the element can be a button vs a link (per ADA)
			window.open("https://www.ecolab.com/expertise-and-innovation/exponential-value-eroi", "_blank");
		},
		whatIsEroiClick : function(){
			// this is so the element can be a button vs a link (per ADA)
			window.open("https://www.ecolab.com/expertise-and-innovation/exponential-value-eroi", "_blank");
		},
	    showOverlay : function(content) {
	    	this.overlayActive = true;
	    	this.overlayContent = content;

	    	this.$nextTick( ()=>{
	    		var dialog = document.querySelector('.overlay_container');
				var firstFocusableElement = dialog.querySelector('.close_button');
				var lastFocusableElement = dialog.querySelector('.learn_more');
				lastFocusableElement.focus();
		    	dialog.addEventListener("keydown",  (e) => {
		    		this.addKeyDown(this, e)
		    	}, false);
	    	});

	    },
    // overlay.vue
    hideOverlay : function() {
      var dialog = document.querySelector('.overlay_container');
      dialog.removeEventListener("keydown", this.addKeyDown, false);

      this.$store.commit('setOverlayKey', false)
      this.overlayActive = false;
      if (this.$store.state.overlayKey.focusedElementBeforeOpenModal) {
        this.$store.state.overlayKey.focusedElementBeforeOpenModal.focus();
        this.$store.commit('setFocusedElementBeforeOpenModal', null);
      }
    },
	},
	computed : {
		...mapGetters([
			'iframe',
			'overlayKey'
		]),
	},
	components : {
		applicationSelector,
	},

}
</script>
