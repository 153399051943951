const state = {
    iframe: true
}

const getters = {
    iframe: state => state.iframe
}

const mutations = {
    setIframe(state, boolean){
        state.iframe = boolean
    }
}

const actions = {
    setIframeState({commit}, boolean){
        commit('setIframe', boolean)
    }
}

export default {
    state,
    mutations,
    getters,
    actions
};