import Vue from 'vue'
import Vuex from 'vuex'
import customerImpact from '@/store/modules/CustomerImpact.js';
import iframe from '@/store/modules/Iframe.js';
import widget from '@/store/modules/Widget.js';
import availableSpace from '@/store/modules/AvailableSpace.js'
import viewport from '@/store/modules/Viewport.js';
import tvd from '@/store/modules/Tvd.js';
import goalsWater from '@/store/modules/GoalsWater';
import goalsFood from '@/store/modules/GoalsFood';
import goalsHealth from '@/store/modules/GoalsHealth';
import goalsClimate from '@/store/modules/GoalsClimate';
import namespace from '@/store/modules/Namespace.js';
import internalView from '@/store/modules/InternalView.js';
import overlayKey from '@/store/modules/OverlayKey.js';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        customerImpact,
        iframe,
        widget,
        availableSpace,
        viewport,
        tvd,
        goalsWater,
        goalsFood,
        goalsHealth,
        goalsClimate,
        namespace,
        internalView,
        overlayKey
    }
})