//when data seections are made to state.  We update a hash in the url
import store from '@/store/index.js';
import router from '@/router/index.js';
import URL from '@/util/url.js';


export default class StateToHash {
    constructor() {
        this.storedTabHash = [];

        //we save the has from each tab, if we go back to a prior tab we load whatever hash was in place before we left
        this.saveHashPerRoute();

        //as user makes choices we save those choices in hash form (that way you can share or refresh) with preselected options
        this.setHashPerSelection();


    }

    setHashPerSelection() {
        store.subscribe((mutation, state) => {

            if (mutation.type == `${store.getters.namespace}setApplication`) {

                let application = store.getters[`${store.getters.namespace}application`];
                URL.setHash({
                    application: application
                });
            }

            if (mutation.type == `${store.getters.namespace}setTechnology`) {
                let technology = store.getters[`${store.getters.namespace}technology`];
                URL.setHash({
                    technology: technology
                });
            }

            if (mutation.type == `${store.getters.namespace}setRegion`) {
                let region = store.getters[`${store.getters.namespace}region`];

                URL.setHash({
                    location: region
                });
            }

            if (mutation.type == `${store.getters.namespace}setMetricUnit`) {
                let unit = store.getters[`${store.getters.namespace}metricUnit`];

                if(!unit){
                    return;
                }

                if (unit.hasOwnProperty('id')) {
                    URL.setHash({
                        unit: unit.id
                    });
                }
            }

            if (mutation.type == `${store.getters.namespace}setImpactArea`) {
                let area = store.getters[`${store.getters.namespace}impactArea`];
                URL.setHash({
                    impactArea: area
                });
            }

            if (mutation.type == `${store.getters.namespace}setPeriod`) {
                let period = store.getters[`${store.getters.namespace}period`];
                URL.setHash({
                    period: period
                });
            }

        });
    }

    saveHashPerRoute() {
        //assign as we swicth tabs
        router.afterEach((to, from) => {

            //store the hash as we leave the "from" path.

            this.storedTabHash[from.path] = URL.parse(from.hash);
            //console.log(this.storedTabHash);

            //reset any saved hash as we move tabs
            if (to.path !== from.path) {
                URL.reset();
                //set our hash to be that of a saved value if it exists
                //this only happens on navigation, not on page load
                //console.log(from);
                //console.log(to);
                URL.setHash(this.storedTabHash[to.path]);
            }

        });
    }
}