import store from '@/store/index.js';
import Vue from 'vue'
import router from '@/router/index.js';

export default class StateToFontSize {
    constructor() {

        this._availableSpace = store.getters.availableSpace;
        this._viewport = store.getters.viewport;
        this._iframe = store.getters.iframe;
        this.vuexModel = "";

        let styleElement = document.createElement('style');
        let head = document.getElementsByTagName('head')[0];
        this.styleContainer = head.appendChild(styleElement);

        // this.fontSizes = [{
        //         screen: 'xs',
        //         size: 50
        //     },
        //     {
        //         screen: 'sm',
        //         size: 70
        //     },
        //     {
        //         screen: 'md',
        //         size: 80
        //     },
        //     {
        //         screen: 'lg',
        //         size: 90
        //     },
        //     {
        //         screen: 'xl',
        //         size: 110
        //     }
        // ];

        this.fontSizes = [{
                screen: 'xxxxs',
                size: 32
            },
            {
                screen: 'xxxs',
                size: 35
            },
            {
                screen: 'xxs',
                size: 40
            },
            {
                screen: 'xs',
                size: 44
            },
            {
                screen: 'sm',
                size: 48
            },
            {
                screen: 'md',
                size: 64
            },
            {
                screen: 'lg',
                size: 78
            },
            {
                screen: 'xl',
                size: 86
            },
            {
                screen: 'xxl',
                size: 97
            },
            {
                screen: 'xxxl',
                size: 110
            }
        ];

        this.addListeners();

        // window.addEventListener( "resize", _.debounce(() =>{
        //     this.sizeFont();
        // }), 50);
    }

    addListeners() {
        store.subscribe((mutation, state) => {
            if (mutation.type == "setNamespace") {
               this.sizeFont();
            }

            if (mutation.type == `${store.getters.namespace}setCount`) {
               this.sizeFont();
            }

            if (mutation.type == "setViewport") {
                this._viewport = store.getters.viewport;
            }

            if (mutation.type == "setAvailableSpace") {
                this._availableSpace = store.getters.availableSpace;
                this.sizeFont();
            }

            if (mutation.type == "setIframe") {
                this._iframe = store.getters.iframe;
            }
        });

    }

    /*
    **  Complicated set of instructions.  Taken verbatim from react
     */
    sizeFont() {
        // Get the available width of the rendered element.
        var availableSpace = this._availableSpace;
        //console.log("availableSpace: " + availableSpace);
        availableSpace = availableSpace - 5; // Remove 5 just in case
        //console.log("availableSpace: " + availableSpace);
        //var fontSize = this.fontSizes[4].size;
        var currentViewport = this._viewport;

        // If the viewport is XL and it is in an iframe don't use the xl max font size because the xl viewport isn't available when in an iframe
        if (this._iframe && (currentViewport == 'xl')) {
            this.fontSizes[4].size = this.fontSizes[3].size;
            //console.log(this.fontSizes[4].size);
        }

        //console.log("currentViewport: " + currentViewport);
        //console.log(this.fontSizes);

        var fontSize;

        if (typeof currentViewport !== 'undefined') {
            var matchedSize = this.fontSizes.filter(function(sizes) {
                return sizes.screen === currentViewport;
            });

            if (matchedSize.length) {
                fontSize = matchedSize[0].size;
            } else {
                fontSize = this.fontSizes[3];
            }
        } else {
            fontSize = this.fontSizes[3];
        }



        var fontRatio = .65;
        var commaRatio = .25;
        var numberWidth;
        var commmaWidth;

        let namespacedCount = store.getters[`${store.getters.namespace}count`];
        //namespace
        var savingsDigits = Math.round(namespacedCount).toString().length;

        //console.log("savingsDigits: " + savingsDigits);

        // Add a digit if TVD to account for the $
        if(store.getters.namespace == "tvd/") {
            savingsDigits++;
        }

        function setFontSizing() {
            numberWidth = fontSize * fontRatio;
            commmaWidth = fontSize * commaRatio;
        }

        setFontSizing();

        // Make sure there is room for all the numbers and adjust font size if needed.
        var numberOfCommas = Math.floor((savingsDigits - 1) / 3);
        var availableSpaceForNumbers = availableSpace - (commmaWidth * numberOfCommas);
        var allNumbersWidth = numberWidth * savingsDigits;

        /*
        console.log("savings digits " + savingsDigits);
        console.log("numberWidth: " + numberWidth);
        console.log("numberOfCommas: " + numberOfCommas);
        console.log("allNumbersWidth: " + allNumbersWidth);
        console.log("availableSpaceForNumbers: " + availableSpaceForNumbers);
        */
        // Reset font sizing based on available space
        if (allNumbersWidth > availableSpaceForNumbers) {
            //console.log("Not enough space");
            fontSize = ((availableSpace / (savingsDigits + (numberOfCommas * (commaRatio / fontRatio)))) / fontRatio);
            setFontSizing();
        }

        //console.log("numberWidth: " + numberWidth);
        //console.log("commmaWidth: " + commmaWidth);

        var numbersWidth = numberWidth * savingsDigits;
        var commasWidth = commmaWidth * numberOfCommas;

        //console.log("numbersWidth: " + numbersWidth);
        //console.log("commasWidth: " + commasWidth);

        var totalWidth = commasWidth + numbersWidth;

        //need to be added to head css
        //".count h1 { font-size: " + fontSize + "px; line-height: " + fontSize + "px; } .count h1 span.number { width: " + numberWidth + "px } .count h1 span.comma { width: " + commmaWidth + "px }"

        let fontStyles = ".count h1 { font-size: " + fontSize + "px; line-height: " + fontSize + "px; } .count h1 span.number { width: " + numberWidth + "px } .count h1 span.comma { width: " + commmaWidth + "px }";


        this.styleContainer.innerHTML = fontStyles;

    }
}