<template>
    <div id="app" :class="[iframe ? 'iframe' : 'fullscreen']">
        <viewport></viewport>
        <div class="container" v-if="!widget">
            <div class="wrapper d-flex flex-column">
                <app-header></app-header>
                <main class="flex-fill not_widget">
                <transition name="slide-fade">
                    <router-view/>
                </transition>
                </main>
                <app-footer></app-footer>
            </div>
        </div>
        <main class="widget" v-else-if="widget">
            <transition name="slide-fade">
                <router-view/>
            </transition>
        </main>
        <overlay></overlay>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import store from '@/store/index.js';
import {mapGetters} from 'vuex';
import appHeader from "@/views/partials/header.vue";
import appFooter from "@/views/partials/footer.vue";
import overlay from "@/views/partials/overlay.vue";
import viewport from '@/components/Viewport.vue';
import URL from "@/util/url.js";
import router from '@/router/index.js';
export default {
    created() {
        window.addEventListener("resize", _.debounce(this.handleAvailableSpace), 50);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleAvailableSpace);
    },
    mounted() {

        //**
        // Some project wide bootstrapping
        //**
        this.handleIframe();
        this.handleArrivingUrlHash();


        this.$store.subscribe((mutation, state) => {
            if (mutation.type != 'setAvailableSpace') {
                this.handleAvailableSpace()
            }

        });


        //window.addEventListener( "resize", this.handleAvailableSpace() );
    },
    computed : {
        ...mapGetters([
            'widget',
            'iframe'
        ])
    },
    watch : {
        '$route' :function(to, from) {
            if(to.path !== from.path){
                this.handleAvailableSpace();
            }
            this.handleArrivingUrlHash()
        },
    },
    methods : {
        ...mapActions([
            'setIframeState',
            'setAvailableSpaceState'
        ]),
        handleArrivingUrlHash : function(){
            URL.init();
            if(URL.hash.view){
                if(URL.hash.view !== "internal"){
                    this.$store.commit('setInternalView', 0);
                } else {
                    this.$store.commit('setInternalView', 1);
                }
            } else {
                this.$store.commit('setInternalView', 0);
            }

            if(URL.hash.type){
                //console.log(URL.hash.type)
                if(URL.hash.type == "widget"){
                    this.$store.commit('setMyWidget', 1);
                } else {
                    this.$store.commit('setMyWidget', 0);
                }
            } else {
                 this.$store.commit('setMyWidget', 0);
            }

        },

        handleIframe : function() {
            // If the page is not in an iframe then broadcast this to the app
            if(window.self == window.top) {
                this.setIframeState(false);
            }
        },

        handleAvailableSpace : function() {
           //console.log(this.$refs)
           this.$nextTick(()=>{ // pause a sec before we try and grab this
                if(document.getElementById("numberContainer")) {
                    let availableSpace = document.getElementById("numberContainer").clientWidth;
                    this.setAvailableSpaceState(availableSpace);
                }
           })
        }
    },
    components : {
        appHeader,
        appFooter,
        overlay,
        viewport
    }
}
</script>
