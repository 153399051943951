<template>
    <div>
        <div v-for="breakpoint in breakpoints" class="device" :id="breakpoint" :class="buildViewportClass(breakpoint)"></div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
     data : function () {
        return {

        }
    },
    mounted(){
        this.setBreakPoint();

        window.addEventListener( "resize", _.debounce(() =>{
            this.setBreakPoint();
        }), 50);
    },
    methods : {
        buildViewportClass : function(breakpoint){
            if(breakpoint == "xxxxs") {
                return `device-${breakpoint}`
            } else {
                return `d-none device-${breakpoint} d-${breakpoint}-block`
            }
        },
        isVisible : function(breakpoint){
            return document.getElementById(breakpoint).offsetParent !== null ? true : false;
        },
        setBreakPoint : function(){
            let currentBreakpoint = "";

            _.each(this.breakpoints, (breakpoint) =>{
                if(this.isVisible(breakpoint)) {
                    currentBreakpoint = breakpoint;
                }
            });

            this.$store.commit('setViewport', currentBreakpoint);
        }
    },

    computed : {
        ...mapGetters([
            'viewports',
            'breakpoints'
        ]),

    }
}
</script>