<template>
    <footer>
        <div class="row align-items-center">
            <div class="col-auto" v-if="!iframe">
                <div class="logo">
                    <a href="https://www.ecolab.com" target="_blank">
                        <img src="@/img/ecolab_logo.svg" alt="Ecolab logo"/>
                    </a>
                </div>
            </div>
            <div class="col">
                <nav class="navbar d-none d-md-block" aria-label="secondary">
                    <div class="navbar-nav flex-row">
                        <ul class="d-flex p-0 m-0">
                            <li class="nav-item">
                                <a href="#" role="button" class="nav-link" @click.prevent="showOverlay('What Is Eroi', $event)">What Is eROI?</a>
                            </li>
                            <li class="nav-item">
                                <a href="#" role="button" class="nav-link" @click.prevent="showOverlay('About This Tool', $event)">About This Tool</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" target="_blank" href="https://www.ecolab.com/about/locations">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div class="col-auto buttons">
                <ul v-if="iframe" class="d-flex p-0 m-0">
                    <li>
                        <button @click="handleReset" class="icon reset btn-sm"><span><RefreshIcon />Reset</span></button>
                    </li>
                    <li>
                        <a :href="fullScreenLink" target="_blank" class="button icon fullscreen_button btn-sm"><FullScreenIcon /><span>Fullscreen</span></a>
                    </li>
                </ul>
                <button v-else @click="handleReset" class="icon reset btn-sm"><RefreshIcon /><span>Reset</span></button>
            </div>
        </div>
    </footer>

</template>

<script>

import {mapGetters} from 'vuex';
import URL from '@/util/url.js';
import RefreshIcon from "@/components/utils/RefreshIcon.vue";
import FullScreenIcon from "@/components/utils/FullScreenIcon.vue";
export default {
	components: {FullScreenIcon, RefreshIcon},
	data : function () {
		return {
			showOverflow : true,
      focusedElementBeforeOpenModal : null,
    }
	},
	computed : {
		...mapGetters([
			'iframe'
		]),
        currentRouteModel: function() {
            if(!this.$route){
                return;
            }

            if(!this.$route.hasOwnProperty('meta')){
                return;
            }

            if(!this.$route.meta.hasOwnProperty('vuexModel')){
                //console.error('this route is missing a vuexModel in routes/index')
                return;
            }
            return `${this.$route.meta.vuexModel}`;
        },
        fullScreenLink: function() {
            let url = window.location.protocol + "//" + window.location.host + ((window.location.pathname == '/') ? '' : window.location.pathname );
            let hash = URL.drop(window.location.hash, 'type');

            return `${url}/#${hash}`;
        }
	},
	methods :{
        showOverlay : function(key, $event) {
            this.$store.commit('setFocusedElementBeforeOpenModal', $event.target);
            this.$store.commit('setOverlayKey', key);
        },
        handleReset : function(){
            URL.reset();
            //lol, one way to make a browser reload.
            this.$router.go(this.$router.currentRoute)
        }
    }
}
</script>
